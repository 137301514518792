import * as React from 'react';
import moment from 'moment';
import Slider from 'react-slick';

import Text from 'components/core/Text';
import AchievementsTypes from '../../Achievements';
import { ACHIEVEMENT_TYPES } from '../../../constants';

import {
  Arrows,
  Content,
  CloseIcon,
  BackgroundStyled,
  Title,
  SubTitle,
  ReachedDateTitle,
  ReachedDateValue,
  Achievement,
  AchievementBadge,
} from './styles';

const SliderArrows = ({ className, to, onClick }) => {
  return (
    <Arrows
      theme="white"
      themeMobile="white"
      arialLabel={to}
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
    />
  );
};

const SETTINGS = {
  // autoplay: true,
  arrows: true,
  dots: true,
  speed: 500,
  prevArrow: <SliderArrows to="prev" />,
  nextArrow: <SliderArrows to="next" />,
  infinite: false,
  swipe: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 765,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        swipe: true,
        dots: false,
        arrows: false,
        infinite: false,
      },
    },
  ],
};

class Achievements extends React.Component {
  handleCloseAchievements = () => {
    const { requestFetchAchievements } = this.props;

    requestFetchAchievements({ markAsViewed: true });
  };

  renderAchievementTitle = notViewedAchievement => {
    // check is achievementType == 5 then we use its name as achievement title

    return notViewedAchievement.type === 5 || notViewedAchievement.type === 4
      ? notViewedAchievement.name
      : ACHIEVEMENT_TYPES[notViewedAchievement.type];
  };

  renderAchievement = achievement => {
    // @TODO Achievement in profile category should be in 5 group type, but for today it is in 4th, so we just increase the group number.
    const achievementType = achievement.type === 5 ? achievement.type - 1 : achievement.type;
    const AchievementEntity = AchievementsTypes[`type_${achievementType}`];
    const type = 'earned';

    if (achievement.type === 4 || achievement.type === 5) {
      if (achievement.name === 'AP Member') {
        const AchievementComponent = AchievementEntity.value_3[type];

        return <AchievementComponent />;
      }

      if (achievement.name === 'Profile Completed') {
        const AchievementComponent = AchievementEntity.value_4[type];

        return <AchievementComponent />;
      }

      if (achievement.name === 'Training Logged') {
        const AchievementComponent = AchievementEntity.value_1[type];

        return <AchievementComponent />;
      }
    }

    const AchievementComponent = AchievementEntity?.[`value_${achievement.timesValue}`][type];

    if (!AchievementComponent) return null;

    return <AchievementComponent />;
  };

  renderAchievements = () => {
    const { notViewedAchievements } = this.props;

    return notViewedAchievements && notViewedAchievements.length
      ? notViewedAchievements.map(notViewedAchievement => {
          return (
            <Achievement key={notViewedAchievement.id}>
              <AchievementBadge>{this.renderAchievement(notViewedAchievement)}</AchievementBadge>
              <Text
                variant="captionSmall"
                weight="bold"
                color="black"
                transform="uppercase"
                align="center"
                mb={7}
              >
                {this.renderAchievementTitle(notViewedAchievement)}
              </Text>
              <ReachedDateTitle>
                <Text variant="text" tag="h4" align="center" color="gray">
                  {notViewedAchievement.description}
                </Text>
              </ReachedDateTitle>
              <ReachedDateValue>
                <Text variant="textSmall" weight="bold" color="gray" align="center">
                  {moment(notViewedAchievement.earnedAt).format('dddd, MMM DD')}
                </Text>
              </ReachedDateValue>
            </Achievement>
          );
        })
      : null;
  };

  render() {
    const { notViewedAchievements } = this.props;

    return (
      <>
        <BackgroundStyled onClick={this.handleCloseAchievements} />
        <Content>
          <CloseIcon onClick={this.handleCloseAchievements} />
          <Title>
            <Text variant="promoHeader" color="grayExtraLight" align="center" isCondensed>
              achievement
            </Text>
            <SubTitle>
              <Text variant="displayX" tag="div" color="black" align="center" transform="uppercase">
                reached
              </Text>
            </SubTitle>
          </Title>
          {notViewedAchievements && notViewedAchievements.length ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Slider {...SETTINGS}>{this.renderAchievements()}</Slider>
          ) : null}
        </Content>
      </>
    );
  }
}

export default Achievements;
