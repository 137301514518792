import styled from 'styled-components';

import { rgba } from 'polished';

import colors from '../../../constants/colors';

import icCloseBlack from '../../../assets/icons/ic_close_black.svg';

import { Background } from '../styles';

export const BackgroundStyled = styled(Background)``;

export const Arrows = styled.button`
  background: ${rgba(colors.main.black, 0.4)} !important;
  border-radius: 2px;
  transition: all 0.3s;
  top: 50%;
  right: -20px;
  transform: translate(-100%, -50%);
  left: initial;
  height: 61px;
  width: 61px;
  z-index: 100;

  &:after {
    display: none;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    opacity: 1;
    border: solid ${colors.main.white};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: translate(-50%, -50%) rotate(135deg);
  }

  &.slick-prev {
    right: initial;
    left: -20px;
  }

  &.slick-next {
    transform: translate(100%, -50%) rotate(180deg);
  }
`;

export const Content = styled.div`
  background-color: ${colors.main.white};
  width: 770px;
  height: 505px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  opacity: 0.99;
  animation: fadein 1s;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;
  z-index: 10002;

  .slick-slider {
    position: static;
    width: 100%;

    .slick-dots {
      transform: translateY(-100%);
      position: absolute;
      bottom: -45px;

      li {
        margin: 0;
        margin-right: 9px;
        height: 8px;
        width: 8px;

        button {
          padding: 0;
          position: relative;
          height: 8px;
          width: 8px;

          &:before {
            background: ${rgba(colors.main.placeholdersAndDisabledBtnsText, 0.6)};
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            height: 8px;
            width: 8px;
          }
        }

        &.slick-active {
          button {
            &:before {
              background: ${rgba(colors.main.placeholdersAndDisabledBtnsText, 1)};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    animation: easein 1s;
    height: 100%;
    width: 100%;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    85% {
      opacity: 0.8;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes easein {
    from {
      transform: translate(-50%, 140%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }
`;

export const CloseIcon = styled.div`
  width: 25px;
  height: 25px;
  background: url(${icCloseBlack});
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px 0 0;
  cursor: pointer;
`;

export const Title = styled.div`
  position: relative;
  width: 100%;
`;

export const SubTitle = styled.div`
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 100%;
`;

export const ReachedDateTitle = styled.div`
  margin: 0 auto 12px;
  width: 500px;

  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 0 40px;
  }
`;

export const ReachedDateValue = styled.div`
  margin: 0 auto;
  width: 89.9%;
`;

export const Achievement = styled.div`
  outline: none;
`;

export const AchievementBadge = styled.div`
  flex-shrink: 0;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 58px;
  margin-bottom: 10px;
  width: 85px;
  position: relative;

  svg {
    font-size: 85px;
  }

  &:nth-of-type(5n + 5) {
    margin-right: 0;
  }

  @media screen and (max-width: 992px) {
    margin: 10px auto;
    margin-top: 50px;
  }
`;
